import React, { useCallback, useEffect, useState } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'

import Layout from '../components/layout'

// Images
import troy from '../images/students/troy.jpg'
import ally from '../images/students/ally.jpg'
import meredith from '../images/students/meredith.png'
import sam from '../images/students/sam.jpg'
import nora from '../images/students/nora.jpg'
import gabe from '../images/students/gabe.jpg'
import david from '../images/students/david.png'

import '../styles/layout-styles.css'
import '../styles/home-styles.css'
import '../styles/student-styles.css'

const StudentsPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  const [studentCards, setStudentCards] = useState([])

  const students = [
    {
      name: 'Samuel Fisher',
      bio: 'Sam is a Sophomore Music Education Major (Euphonium) from Latrobe, PA',
      image: sam,
    },
    {
      name: 'Meredith Fuller',
      bio: 'Meredith is a first year tuba performance masters student from Lebanon, PA, and is currently the Tuba Euphonium TA',
      image: meredith,
    },
    {
      name: 'Gabriel Newvine',
      bio: 'Gabe is a Sophmore music education major.',
      image: gabe,
    },
    {
      name: 'David Popkin',
      bio: 'David is a tuba performance major (BA), and is also on a pre med track. David is from Randolph NJ',
      image: david,
    },
    {
      name: 'Troy Rowlands',
      bio: 'Troy is a Junior Music Education Major (Tuba) from Pittsburgh, PA',
      image: troy,
    },
    {
      name: 'Nora Stark',
      bio: 'Nora is a Senior Tuba Performance major',
      image: nora,
    },
    {
      name: 'Ally Ward',
      bio: 'Ally is a Senior Tuba Performance Major from Shepherdstown, WV',
      image: ally,
    },
  ]

  const generateStudents = useCallback(
    (isMobile) => {
      const studentCardsTemp = []

      students.forEach((student) => {
        studentCardsTemp.push(
          <div className='studentItem'>
            <Card
              variant={isMobile ? '' : 'outlined'}
              style={{ display: 'flex' }}
            >
              <CardMedia
                className={'studentImage'}
                image={student.image}
                title={student.name}
              />
              <CardActionArea>
                <CardContent>
                  <p className='studentTitle'>{student.name}</p>
                  <p className='studentDescription'>{student.bio}</p>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        )
      })

      setStudentCards(studentCardsTemp)
    },
    [students]
  )

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  useEffect(() => {
    generateStudents(isMobile)
  }, [isMobile])

  return (
    <Layout>
      <div className='text-3xl my-6 text-nittanyNavy text-center md:my-14 lg:text-6xl'>
        Students
      </div>
      <div className='content'>{studentCards}</div>
    </Layout>
  )
}

export default StudentsPage
